import { state, style, trigger, transition, animate, AnimationEvent } from '@angular/animations';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PdfViewerService } from '@shared/services/pdf-viewer.service';
import { AppRoutingService } from '@shared/services/app-routing.service';

@Component({
  selector: 'invoice-review',
  templateUrl: './invoice-review.component.html',
  styleUrls: ['./invoice-review.component.scss'],
  providers: [PdfViewerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
    state('open', style({
      backgroundColor: 'rgba(54, 35, 112, 0.8)',
    })),
    state('close', style({
      backgroundColor: 'rgba(54, 35, 112, 0)',
    })),
    transition('close => open', animate('.5s 1300ms ease')),
  ]),
    trigger('slideRightPanel', [
      state('open', style({
        right: '0px'
      })),
      state('close', style({
        right: '-850px'
      })),
      transition('close => open', animate('500ms ease')),
    ]),
    trigger('slideLeftPanel', [
      state('open', style({
        right: '860px',
        opacity: '1'
      })),
      state('close', style({
        right: '0px',
        opacity: '0'
      })),
      transition('close => open', animate('700ms ease')),
    ]),
    trigger('slideBottomPanel', [
      state('open', style({
        bottom: '0px'
      })),
      state('close', style({
        bottom: '-68px'
      })),
      transition('close => open', animate('700ms ease')),
    ])]
},
)
export class InvoiceReviewComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);

  animationState: string = 'close';
  isOverlayShown: boolean = false;
  isPanelsShown: string = 'close';

  invoiceId: number;

  private readonly destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        const invoiceIdParam = params && params[AppRoutingService.DETAILS_OBJECT_ID_PARAM_NAME];
        this.invoiceId = invoiceIdParam && Number.parseInt(invoiceIdParam, 10);
      });
  }

  handleIsDataLoaded(isDataLoaded: boolean): void {
    this.isOverlayShown = isDataLoaded;
  }

  onFadeInEnd(event: AnimationEvent): void {
    if (event.toState === 'open') {
      this.isPanelsShown = 'open';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
